.experienceSection {
  /* padding: 2rem; */
  /* background-color: #f5f5f5; */
}

.heading {
  font-size: 2rem;
  text-align: center;
  color: #333;
  margin-bottom: 2rem;
}

.experienceCard {
  /* background-color: #1b1a2e49 !important; */
  box-shadow: 0 4px 5px 3px rgba(119, 53, 136, 0.459) !important;
  /* backdrop-filter: blur(15px) !important; */
  border: 1px solid #623686;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  transition: transform 0.2s;
}

.experienceCard:hover {
  transform: translateY(-5px);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.company {
  font-size: 1.5rem;
  font-weight: bold;
  color: #623686;
}

.role {
  font-size: 1rem;
  color: #ffffff;
  font-weight: 500;
}

.details {
  font-size: 0.9rem;
  color: #ffffff;
  margin: 0.5rem 0;
}

.date {
  margin-right: 1rem;
}

.location {
  font-style: italic;
}

.description {
  font-size: 1rem;
  color: #bcb1b1;
  margin: 1rem 0;
}

.technologies {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.techBadge {
  border: 1px solid #623686;
  color: #623686;
  padding: 0.3rem 0.8rem;
  border-radius: 5px;
  font-size: 0.8rem;
  font-weight: 500;
}

/* Responsive Design */
@media (min-width: 768px) {
  .experienceSection {
    padding: 3rem;
  }

  .experienceCard {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 1024px) {
  .experienceCard {
    flex-direction: row;
    align-items: center;
  }

  .header {
    flex: 1;
  }

  .description {
    flex: 2;
  }

  .technologies {
    flex: 1;
    justify-content: flex-end;
  }
}

@media (min-width: 1440px) {
  /* .experienceSection {
    padding: 5rem 8rem;
  } */

  .header {
    flex-direction: column;
  }

  .experienceCard {
    padding: 2.5rem;
  }

  .company {
    font-size: 1.8rem;
  }

  .role,
  .date,
  .location {
    font-size: 1.2rem;
  }

  .description {
    font-size: 1.2rem;
  }

  .techBadge {
    padding: 0.4rem 1rem;
    font-size: 0.9rem;
  }
}
